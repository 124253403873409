import React from 'react'
import { Header, List } from 'semantic-ui-react'

import Layout from '../components/layout'
import {
  LayoutSection,
  JustifyParagraph,
} from '../components/layout-components'

const PrivacyPage = () => (
  <Layout>
    <LayoutSection header="Datenschutz&shy;erkl&auml;rung" text>
      <Header size="medium">1. Datenschutz auf einen Blick</Header>
      <Header size="small">Allgemeine Hinweise</Header>
      <JustifyParagraph>
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick
        dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
        diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
        denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
        unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </JustifyParagraph>
      <Header size="small">Datenerfassung auf dieser Website</Header>
      <Header size="tiny" sub>
        Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
      </Header>
      <JustifyParagraph>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum
        dieser Website entnehmen.
      </JustifyParagraph>
      <Header size="tiny" sub>
        Wie erfassen wir Ihre Daten?
      </Header>
      <JustifyParagraph>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in
        ein Kontaktformular eingeben.
      </JustifyParagraph>
      <JustifyParagraph>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
        Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
        automatisch, sobald Sie diese Website betreten.
      </JustifyParagraph>
      <Header size="tiny" sub>
        Wof&uuml;r nutzen wir Ihre Daten?
      </Header>
      <JustifyParagraph>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse
        Ihres Nutzerverhaltens verwendet werden.
      </JustifyParagraph>
      <Header size="tiny" sub>
        Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
      </Header>
      <JustifyParagraph>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
        Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
        Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung
        oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung
        zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung
        jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
        Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
        steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
        Aufsichtsbeh&ouml;rde zu.
      </JustifyParagraph>
      <JustifyParagraph>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
        sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </JustifyParagraph>
      <Header size="medium">
        2. Hosting und Content Delivery Networks (CDN)
      </Header>
      <Header size="small">Externes Hosting</Header>
      <JustifyParagraph>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die &uuml;ber eine Website generiert werden, handeln.
      </JustifyParagraph>
      <JustifyParagraph>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
        gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
        1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
        effizienten Bereitstellung unseres Online-Angebots durch einen
        professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </JustifyParagraph>
      <JustifyParagraph>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </JustifyParagraph>
      <p>Wir setzen folgenden Hoster ein:</p>
      <p>
        netcup GmbH
        <br />
        Daimlerstra&szlig;e 25
        <br />
        D-76185 Karlsruhe
      </p>
      <Header size="medium">
        3. Allgemeine Hinweise und Pflicht&shy;informationen
      </Header>
      <Header size="small">Datenschutz</Header>
      <JustifyParagraph>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
        Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
        und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerkl&auml;rung.
      </JustifyParagraph>
      <JustifyParagraph>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
        Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
        wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
        Zweck das geschieht.
      </JustifyParagraph>
      <JustifyParagraph>
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
        (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
        aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
        durch Dritte ist nicht m&ouml;glich.
      </JustifyParagraph>
      <Header size="small">Hinweis zur verantwortlichen Stelle</Header>
      <p>
        Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
        Website ist:
      </p>
      <p>
        Ronald Steinke IT-Service
        <br />
        Eldenaer Str. 6<br />
        10247 Berlin
      </p>
      <p>
        Telefon: 0174/2540428
        <br />
        E-Mail: ronald.steinke@steinke-it-service.de
      </p>
      <JustifyParagraph>
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person,
        die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
        E-Mail-Adressen o. &Auml;.) entscheidet.
      </JustifyParagraph>
      <Header size="small">Speicherdauer</Header>
      <JustifyParagraph>
        Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn
        Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
        Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
        gel&ouml;scht, sofern wir keinen anderen rechtlich zul&auml;ssigen&nbsp;
        Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
        (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
        letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser
        Gr&uuml;nde.
      </JustifyParagraph>
      <Header size="small">
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </Header>
      <JustifyParagraph>
        Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
        ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
        bereits erteilte Einwilligung jederzeit widerrufen. Die
        Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
      </JustifyParagraph>
      <Header size="small">
        Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
        sowie gegen Direktwerbung (Art. 21 DSGVO)
      </Header>
      <JustifyParagraph>
        WENN DIE DATEN&shy;VERARBEITUNG AUF GRUND&shy;LAGE VON ART. 6 ABS. 1
        LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDER&shy;ZEIT DAS RECHT, AUS
        GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
        VER&shy;ARBEITUNG IHRER PERSONEN&shy;BEZOGENEN DATEN WIDER&shy;SPRUCH
        EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
        GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTS&shy;GRUNDLAGE, AUF DENEN
        EINE VER&shy;ARBEITUNG BERUHT, ENT&shy;NEHMEN SIE DIESER
        DATEN&shy;SCHUTZ&shy;ERKL&Auml;RUNG. WENN SIE WIDER&shy;SPRUCH EINLEGEN,
        WERDEN WIR IHRE BETROFFENEN PERSONEN&shy;BEZOGENEN DATEN NICHT MEHR
        VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZ&shy;W&Uuml;RDIGE
        GR&Uuml;NDE F&Uuml;R DIE VER&shy;ARBEITUNG NACH&shy;WEISEN, DIE IHRE
        INTERESSEN, RECHTE UND FREI&shy;HEITEN &Uuml;BER&shy;WIEGEN ODER DIE
        VER&shy;ARBEITUNG DIENT DER GELTEND&shy;MACHUNG, AUS&Uuml;BUNG ODER
        VER&shy;TEIDIGUNG VON RECHTS&shy;ANSPR&Uuml;CHEN (WIDER&shy;SPRUCH NACH
        ART. 21 ABS. 1 DSGVO).
      </JustifyParagraph>
      <JustifyParagraph>
        WERDEN IHRE PERSONEN&shy;BEZOGENEN DATEN VERARBEITET, UM
        DIREKT&shy;WERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDER&shy;ZEIT
        WIDER&shy;SPRUCH GEGEN DIE VER&shy;ARBEITUNG SIE BETREFFENDER
        PERSONEN&shy;BEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN;
        DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER
        DIREKT&shy;WERBUNG IN VERBINDUNG STEHT. WENN SIE WIDER&shy;SPRECHEN,
        WERDEN IHRE PERSONEN&shy;BEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
        ZWECKE DER DIREKT&shy;WERBUNG VERWENDET (WIDER&shy;SPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </JustifyParagraph>
      <Header size="small">
        Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde
      </Header>
      <JustifyParagraph>
        Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen
        ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem
        Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes
        oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das
        Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
        oder gerichtlicher Rechtsbehelfe.
      </JustifyParagraph>
      <Header size="small">
        Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
      </Header>
      <JustifyParagraph>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich
        oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
        aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der
        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </JustifyParagraph>
      <Header size="small">SSL- bzw. TLS-Verschl&uuml;sselung</Header>
      <JustifyParagraph>
        Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
        &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
        oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen
        Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo;
        auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.
      </JustifyParagraph>
      <JustifyParagraph>
        Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
        die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
        werden.
      </JustifyParagraph>
      <Header size="small">Auskunft, L&ouml;schung und Berichtigung</Header>
      <JustifyParagraph>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck
        der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden.
      </JustifyParagraph>
      <Header size="small">
        Recht auf Einschr&auml;nkung der Verarbeitung
      </Header>
      <JustifyParagraph>
        Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das
        Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
        F&auml;llen:
      </JustifyParagraph>
      <List bulleted relaxed size="small">
        <List.Item>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu
          &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie
          das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </List.Item>
        <List.Item>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
          L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
        </List.Item>
        <List.Item>
          Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
          sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
          Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
          L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </List.Item>
        <List.Item>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
          vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
          &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </List.Item>
      </List>
      <JustifyParagraph>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
        eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
        Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
        wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder
        eines Mitgliedstaats verarbeitet werden.
      </JustifyParagraph>
      <Header size="small">Widerspruch gegen Werbe-E-Mails</Header>
      <JustifyParagraph>
        Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
        Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
        angeforderter Werbung und Informationsmaterialien wird hiermit
        widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich
        rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-E-Mails, vor.
      </JustifyParagraph>
      <Header size="medium">4. Datenerfassung auf dieser Website</Header>
      <Header size="small">Server-Log-Dateien</Header>
      <JustifyParagraph>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        &uuml;bermittelt. Dies sind:
      </JustifyParagraph>
      <List bulleted relaxed size="small">
        <List.Item>Browsertyp und Browserversion</List.Item>
        <List.Item>verwendetes Betriebssystem</List.Item>
        <List.Item>Referrer URL</List.Item>
        <List.Item>Hostname des zugreifenden Rechners</List.Item>
        <List.Item>Uhrzeit der Serveranfrage</List.Item>
        <List.Item>IP-Adresse</List.Item>
      </List>
      <p>
        Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
        nicht vorgenommen.
      </p>
      <JustifyParagraph>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        technisch fehlerfreien Darstellung und der Optimierung seiner Website
        &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.
      </JustifyParagraph>
      <Header size="small">Anfrage per E-Mail, Telefon oder Telefax</Header>
      <JustifyParagraph>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </JustifyParagraph>
      <JustifyParagraph>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags
        zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher
        Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen
        beruht die Verarbeitung auf unserem berechtigten Interesse an der
        effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
        lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
        sofern diese abgefragt wurde.
      </JustifyParagraph>
      <JustifyParagraph>
        Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
        verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
        Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
        Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
        Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash;
        insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
        unber&uuml;hrt.
      </JustifyParagraph>
      <p>
        Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
      </p>
    </LayoutSection>
  </Layout>
)

export default PrivacyPage
